import { theme as nuvoTheme } from '@nuvocargo/nuvo-styleguide/lib/theme';

const { colors, outlines, colorWithAlpha } = nuvoTheme;

const sizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  sm: 968,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

const device = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = `
    (max-width: ${sizes[label]}px)
  `;

  return acc;
}, {});

const mediaSizes = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = `${sizes[label]}px`;

  return acc;
}, {});

const theme = {
  colors,
  colorWithAlpha,
  outlines,
  device,
  mediaSizes,
};

export default theme;
