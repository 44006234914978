import { useLocalStorage } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import * as userApi from 'core/api/user';
import { LANG_PREF_LOCAL_STORAGE_KEY } from 'core/constants';

const useLanguagePref = () => {
  const { i18n } = useTranslation();
  const queryClient = useQueryClient();
  const updateLanguageMutation = useMutation(userApi.updateLanguage, {
    onSuccess: () => {
      queryClient.invalidateQueries('carriers/check-auth');
    },
  });

  const [languagePref, setLanguagePref] = useLocalStorage(
    LANG_PREF_LOCAL_STORAGE_KEY,
    'en',
    value => value,
    value => value
  );

  const updateLanguage = value => {
    i18n.changeLanguage(value);
    setLanguagePref(value);
  };

  return {
    languagePref,
    setLanguagePref: updateLanguage,
    updateLanguageMutation,
  };
};

export default useLanguagePref;
