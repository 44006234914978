import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useUser } from 'context/user';
import EventTracker from 'core/integrations/eventTracker';
import lazyWithRetry from 'core/utils/lazyWithRetry';

import { Spinner } from './components/generic/kit/index';

const AuthenticatedApp = lazyWithRetry(() =>
  import('./routes/authenticated-routes')
);
const UnauthenticatedApp = lazyWithRetry(() =>
  import('./routes/unauthenticated-routes')
);

function App() {
  const { pathname } = useLocation();
  const { isAuthenticated, isLoading, backUrl } = useUser();

  useEffect(() => {
    EventTracker.track('page_visited', { pathname });

    return () => {
      EventTracker.track('page_left', { pathname });
    };
  }, [pathname]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isAuthenticated) {
    return <AuthenticatedApp />;
  }

  return <UnauthenticatedApp backUrl={backUrl} />;
}

export default App;
