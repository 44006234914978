import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import LastUsed from 'locize-lastused';
import { initReactI18next } from 'react-i18next';

import { LANG_PREF_LOCAL_STORAGE_KEY } from 'core/constants';
import { isDevelopment } from 'core/utils/lib';

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
  apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
  referenceLng: 'en',
};

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(LastUsed)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      lookupQuerystring: 'language',
      lookupLocalStorage: LANG_PREF_LOCAL_STORAGE_KEY,
    },
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    saveMissing: isDevelopment,
    debug: isDevelopment,
    supportedLngs: ['en', 'es'],
    react: {
      useSuspense: true,
      bindI18n: 'languageChanged',
    },
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'en',
  });

export default i18n;
