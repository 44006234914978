import { createContext, useContext, useEffect } from 'react';

import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';

import { useAuth, useSignOut } from 'core/hooks';
import EventTracker from 'core/integrations/eventTracker';

export const Context = createContext();

export const Provider = ({ children }) => {
  const {
    isAuthenticated,
    currentUser,
    backUrl,
    isReadyForReview,
    isLoading,
    isReadyToOperate,
    isComplianceRejection,
    isProfiled,
  } = useAuth();

  const { signOut, hasSignedOut } = useSignOut();

  useEffect(() => {
    if (currentUser?.hasValidCredentials) {
      EventTracker.identify(currentUser.id, currentUser.companyId, {
        billing_country: currentUser.country,
        email: currentUser.email,
        fullName: currentUser.displayName,
        language: currentUser.language,
        roles: currentUser.roles?.join(', ') || '',
      });

      Sentry.configureScope(function (scope) {
        scope.setUser({
          id: currentUser.id,
          email: currentUser.email,
          username: currentUser.displayName,
          language: currentUser.language,
          roles: currentUser.roles?.join(', ') || '',
          ip_address: '{{auto}}',
        });

        scope.setContext('company', {
          companyId: currentUser.companyId,
          companyName: currentUser.companyName,
          country: currentUser.country,
        });
      });

      FullStory.identify(currentUser.companyId, {
        email: currentUser.email,
        displayName: currentUser.displayName,
        companyId: currentUser.companyId,
        companyName_str: currentUser.companyName,
        country_str: currentUser.country,
        language: currentUser.language,
        roles: currentUser.roles?.join(', ') || '',
      });
    }
  }, [currentUser]);

  return (
    <Context.Provider
      value={{
        isReadyForReview,
        isAuthenticated,
        backUrl: !hasSignedOut && backUrl,
        signOut,
        currentUser,
        isLoading,
        isReadyToOperate,
        isComplianceRejection,
        isProfiled,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export function useUser() {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }

  return context;
}
