import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
export default async function request(
  path,
  method,
  {
    body,
    headers,
    isFormData = false,
    baseUrl,
    params,
    withCredentials = true,
    ...axiosConfig
  } = {}
) {
  // axios.defaults.withCredentials = true;
  const response = await axios({
    method: method,
    withCredentials: withCredentials,
    baseURL: baseUrl ? baseUrl : API_URL,
    url: path,
    params,
    data: isFormData ? body : JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...headers,
    },
    ...axiosConfig,
  });

  return response;
}
