import React from 'react';

import * as Sentry from '@sentry/react';

import {
  ErrorPage,
  Error403Page,
  Error404Page,
  NetworkErrorPage,
} from 'components/common/app';
import {
  isForbiddenError,
  isNotFoundError,
  isUnauthorizedError,
} from 'core/utils/httpErrors';

const initialState = {
  hasError: false,
  httpErrorStatus: null,
  errorMessage: null,
};

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      httpErrorStatus: error?.response?.status,
      errorMessage: error?.message,
    };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, { extra: errorInfo });
    if (isUnauthorizedError(this.state.httpErrorStatus)) {
      this.setState(state => ({
        ...state,
        ...initialState,
      }));
    }
  }

  render() {
    if (this.state.errorMessage === 'Network Error') {
      return <NetworkErrorPage />;
    }

    if (isForbiddenError(this.state.httpErrorStatus)) {
      return <Error403Page />;
    }

    if (isNotFoundError(this.state.httpErrorStatus)) {
      return <Error404Page />;
    }

    if (this.state.hasError) {
      return <ErrorPage />;
    } else {
      return this.props.children;
    }
  }
}
