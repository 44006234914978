import { canada, mexico, usa } from '@nuvocargo/nuvo-styleguide/Icons/Flags';
import { countryShortNameToFlag as nuvoCountryShortNameToFlag } from '@nuvocargo/nuvo-styleguide/lib/utils';
import objectHasProperty from 'lodash/has';
import mergeObjects from 'lodash/merge';
import pickProperties from 'lodash/pick';
import moment from 'moment';
import { isEmpty, isNil } from 'ramda';

export const FREIGHT_LINE_ITEM = 'freight';
export const ADDITIONAL_LINE_ITEM = 'additional';
export const EMPTY_LINE_ITEM = 'empty';

const formatDate = (date, format, local = false) => {
  if (!date) return '';

  const momentDate = moment.utc(date);

  return momentDate.isValid() ? momentDate.local(local).format(format) : '';
};

export function formatTitleDate(datetime) {
  return formatDate(datetime, 'DD MMM, h:mm a');
}

export function formatCellDate(datetime) {
  return formatDate(datetime, 'DD MMM, h:mm a');
}

const formatUSD = amount => {
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

const pluralize = (count, t) => {
  return `${count} ${+count !== 1 ? t('trucks') : t('truck')}`;
};

const thousandCommaSeparator = amount => {
  return Number(amount).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export { formatDate, formatUSD, pluralize, thousandCommaSeparator };

export function toTitleCase(str) {
  return str
    ? str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      })
    : '';
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export const debounce = (func, wait, immediate) => {
  var timeout;

  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

//Returns mexico or USA depending on the country name coming from the backend
export const getCountryName = countryName => {
  switch (countryName) {
    case 'USA':
      return 'usa';
    case 'Mexico':
      return 'mexico';
    case 'Canada':
      return 'canada';
    default:
      return undefined;
  }
};

export const isDevelopment =
  process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';

export const capitalize = (str = '') =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const countryShortNameToFlag = country =>
  ({
    US: usa,
    MX: mexico,
    CA: canada,
  }[country?.toUpperCase()]);

export const mapLanguageToCountry = {
  en: usa,
  es: mexico,
};

export const getFile = fileList => Array.from(fileList)[0];

export const isFileCsv = file =>
  file.type.toLowerCase() === 'type/csv' ||
  file.name.toLowerCase().endsWith('.csv');

export const toFormData = values => {
  let formData = new FormData();
  for (let value in values) {
    if (Array.isArray(values[value])) {
      values[value].forEach(arrayValue => {
        formData.append(`${value}[]`, arrayValue);
      });
    } else {
      formData.append(value, values[value]);
    }
  }

  return formData;
};

function buildFormData(formData, data, parentKey) {
  if (data && Object.keys(data).length && typeof data === 'object') {
    Object.keys(data).forEach(key => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else if (hasValue(data)) {
    formData.append(parentKey, data);
  }
}

function buildFormDataWithEmpty(formData, data, parentKey) {
  if (data && Object.keys(data).length && typeof data === 'object') {
    Object.keys(data).forEach(key => {
      buildFormDataWithEmpty(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else if (data != null) {
    formData.append(parentKey, data);
  }
}

export const jsonToFormData = data => {
  const formData = new FormData();
  buildFormData(formData, data);

  return formData;
};

export const jsonToFormDataWithEmpty = data => {
  const formData = new FormData();
  buildFormDataWithEmpty(formData, data);

  return formData;
};

export const isMexico = country => ['mx', 'MX'].includes(country);

export const isFunction = f => typeof f === 'function';

export const hasValue = value => !isEmpty(value);

export const randomID = key => `${key ? key + '-' : ''}${new Date().getTime()}`;

export const isLastIndexOf = (index, array) => index === array.length - 1;

export const buildRouteValue = (
  value = {},
  labelGenerator = generateCityLabel
) => {
  if (isEmpty(value)) {
    return null;
  }

  return {
    ...value,
    label: labelGenerator(value),
    value: value.id,
    icon: { name: nuvoCountryShortNameToFlag(value.country) },
  };
};

export const generateCityLabel = option =>
  `${option.name}, ${option.parents.state}`;

export const generateZipLabel = option =>
  `${option.name}, ${option.parents.city}, ${option.parents.state}`;

const valueIsUploadedFile = value =>
  Object.keys(value).includes('url') && !(value instanceof File);

export const isValidFile = value => {
  // This checks first for null values then for empty strings
  // We cannot check both of them in a single run: check ramda documentation
  // https://ramdajs.com/docs/#isEmpty
  // https://ramdajs.com/docs/#isNil

  if (!isNil(value)) {
    if (!isEmpty(value)) {
      return !valueIsUploadedFile(value);
    }
  }

  return false;
};

export const formatDocuments = values => {
  const payload = {};
  for (let value in values) {
    if (isValidFile(values[value])) {
      payload[value] = values[value];
    }
  }

  return payload;
};

export const FILE_TYPES = {
  pdf: 'application/pdf',
  xml: 'application/xml',
  txml: 'text/xml',
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
};

export const buildInputFileObject = value => {
  if (value?.filename) {
    const name = value.filename.slice(0, value.filename.lastIndexOf('.'));
    const type = value.filename.slice(value.filename.lastIndexOf('.') + 1);

    return {
      ...value,
      name,
      type: FILE_TYPES[type],
    };
  }

  return null;
};

export const currencyFormatter = (amount, fallback = '') => {
  if (!amount) return fallback;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(amount);
};

export const getLineItemByType = (lineItems, type) =>
  lineItems?.find(item => item.identifier === type);

export const isValueSet = value => !isNil(value) && !isEmpty(value);

export const requiredLabelSuffix = value => `${value}*`;

export const buildAmplitudeBillingEventObject = ({ data } = {}) => {
  const propertiesToGet = ['name', 'type', 'lastModifiedDate'];
  const amplitudeBillingEventObject = {};

  const hasPod = objectHasProperty(data, 'pod');
  const hasInvoice = objectHasProperty(data, 'lineItems.freight.invoice');
  const hasInvoiceXml = objectHasProperty(data, 'lineItems.freight.invoiceXml');
  const hasAdditionalCost = objectHasProperty(
    data,
    'lineItems.additional.costCents'
  );
  const hasAdditionalInvoice = objectHasProperty(
    data,
    'lineItems.additional.invoice'
  );
  const hasAdditionalInvoiceXml = objectHasProperty(
    data,
    'lineItems.additional.invoiceXml'
  );

  if (hasPod) {
    const pod = pickProperties(data.pod, propertiesToGet);

    mergeObjects(amplitudeBillingEventObject, { pod });
  }

  if (hasInvoice) {
    const invoice = pickProperties(
      data.lineItems.freight.invoice,
      propertiesToGet
    );

    mergeObjects(amplitudeBillingEventObject, {
      lineItems: { freight: { invoice } },
    });
  }

  if (hasInvoiceXml) {
    const invoiceXml = pickProperties(
      data.lineItems.freight.invoiceXml,
      propertiesToGet
    );

    mergeObjects(amplitudeBillingEventObject, {
      lineItems: { freight: { invoiceXml } },
    });
  }

  if (hasAdditionalCost) {
    const { costCents } = data.lineItems.additional;

    mergeObjects(amplitudeBillingEventObject, {
      lineItems: { additional: { costCents } },
    });
  }

  if (hasAdditionalInvoice) {
    const invoice = pickProperties(
      data.lineItems.additional.invoice,
      propertiesToGet
    );

    mergeObjects(amplitudeBillingEventObject, {
      lineItems: { additional: { invoice } },
    });
  }

  if (hasAdditionalInvoiceXml) {
    const invoiceXml = pickProperties(
      data.lineItems.additional.invoiceXml,
      propertiesToGet
    );

    mergeObjects(amplitudeBillingEventObject, {
      lineItems: { additional: { invoiceXml } },
    });
  }

  return amplitudeBillingEventObject;
};
