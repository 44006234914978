import { Box } from 'components/generic/kit';
export default function Stack({
  space,
  axis = 'vertical',
  center,
  children,
  styles,
  ...props
}) {
  const isVertical = axis === 'vertical';

  return (
    <Box
      styles={{
        root: {
          display: 'flex',
          alignItems: center && 'center',
          flexDirection: isVertical ? 'column' : 'row',
          '&>*+*': {
            [isVertical ? 'marginTop' : 'marginLeft']: space,
          },
          ...styles?.root,
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
}
