import { colorWithAlpha } from '@nuvocargo/nuvo-styleguide/lib/theme';
import styled from 'styled-components';

export default function ProgressBar({ fillPercentage = 0, fixed = false }) {
  if (process.env.REACT_APP_NEW_ONBOARDING !== 'true') return null;

  return (
    <StyledContainer fixed={fixed}>
      <StyledProgressBar fillPercentage={fillPercentage} />
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  background-color: ${colorWithAlpha('softGreen/10')};
  width: 100%;
  z-index: 2;
  ${({ theme, fixed }) => `
    @media screen and (min-width: ${theme.mediaSizes.laptop}) {
      position: ${fixed ? 'fixed' : ''};
    }
  `}
`;

const StyledProgressBar = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.forest};
  `}
  height: 8px;
  width: ${({ fillPercentage }) => fillPercentage}%;
`;
