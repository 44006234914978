import amplitude from 'amplitude-js';

class EventTracker {
  init() {
    if (process.env.NODE_ENV === 'development') return;

    try {
      amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);
    } catch {
      console.warn('Analytics init failed');
    }
  }

  identify(userId, carrierId, userInfo = {}) {
    if (process.env.NODE_ENV === 'development') return;

    try {
      amplitude.getInstance().setUserId(userId?.toString());
      amplitude.getInstance().setGroup('companyId', carrierId?.toString());
      amplitude.getInstance().setUserProperties(userInfo);
    } catch {
      console.warn('Analytics identify failed');
    }
  }

  track(eventName, eventProperties = {}) {
    if (process.env.NODE_ENV === 'development') return;

    try {
      amplitude.getInstance().logEvent(eventName, eventProperties);
    } catch {
      console.warn('Analytics event failed');
    }
  }
}

export default new EventTracker();
