import * as FullStory from '@fullstory/browser';

import { isDevelopment } from 'core/utils/lib';

FullStory.init({
  orgId: process.env.REACT_APP_FULLSTORY_ORG_ID,
  devMode: isDevelopment,
});

export const createFsEvent = (name, properties) => {
  try {
    FullStory.event(name, properties);
  } catch (e) {
    console.warn(`FS analytics failed for event: ${name}`);
  }
};
