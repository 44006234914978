import { useEffect, useState } from 'react';

import { useLocalStorage } from '@mantine/hooks';
import {
  CTAButton,
  Heading,
  Text,
  usePageHeader,
} from '@nuvocargo/nuvo-styleguide';
import { STATUS_INFO } from '@nuvocargo/nuvo-styleguide/constants';
import FullscreenModal from '@nuvocargo/nuvo-styleguide/Modals/FullscreenModal';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player/youtube';
import { useHistory } from 'react-router-dom';

import { LOADS_DASHBOARD_BANNER_LOCAL_STORAGE_KEY } from 'core/constants';
import { app } from 'routes/paths';

const CLOSE_BANNER_TIMES_LIMIT = 3;
const EN_VIDEO_URL = 'https://youtu.be/1hWgS6UO0pI';
const ES_VIDEO_URL = 'https://youtu.be/LNg9m1iyA14';

const LoadsDashboardBanner = () => {
  const { t, i18n } = useTranslation();
  const { showBanner, hideBanner } = usePageHeader();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const [counter, setCounter] = useLocalStorage({
    key: LOADS_DASHBOARD_BANNER_LOCAL_STORAGE_KEY,
    defaultValue: 0,
    getInitialValueInEffect: false,
  });

  const increaseCounter = () => setCounter(current => current + 1);

  const currentLanguageVideo =
    i18n.language === 'es' ? ES_VIDEO_URL : EN_VIDEO_URL;

  useEffect(() => {
    if (counter >= CLOSE_BANNER_TIMES_LIMIT) {
      hideBanner();
    } else {
      showBanner({
        children: (
          <span className="cursor-pointer" onClick={() => setIsModalOpen(true)}>
            {t('load-dashboard-launch-banner-text')}
          </span>
        ),
        type: STATUS_INFO,
      });
    }

    return () => {
      hideBanner();
    };
  }, [counter]);

  const onModalClose = () => {
    increaseCounter();
    if (history.location.pathname === app.loads) {
      setIsModalOpen(false);
    } else {
      history.push(app.loads);
    }
  };

  return (
    <FullscreenModal isOpen={isModalOpen} onOpenChange={onModalClose}>
      <div className="text-center">
        <div className="relative mb-9 pt-[56.25%]">
          <ReactPlayer
            className="absolute top-0 left-0"
            url={currentLanguageVideo}
            width="100%"
            height="100%"
            playing={true}
            loop={true}
            controls={true}
            playsinline={true}
            config={{
              youtube: {
                playerVars: {
                  disablekb: 1,
                  fs: 0,
                  iv_load_policy: 3,
                  modestbranding: 1,
                  rel: 0,
                },
              },
            }}
          />
        </div>
        <Heading variant="h4">{t('load-dashboard-launch-modal-title')}</Heading>
        <Text className="mt-4 block">
          {t('load-dashboard-launch-modal-text')}
        </Text>
        <CTAButton onClick={onModalClose} className="mx-auto mt-14">
          {t('load-dashboard-launch-modal-button')}
        </CTAButton>
      </div>
    </FullscreenModal>
  );
};

export default LoadsDashboardBanner;
