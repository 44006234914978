import * as Sentry from '@sentry/browser';
import SentryFullStory from '@sentry/fullstory';
import { Integrations } from '@sentry/tracing';

export default () => {
  if (process.env.NODE_ENV === 'development') return;

  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [
      new Integrations.BrowserTracing(),
      new SentryFullStory(process.env.REACT_APP_SENTRY_ORG_SLUG),
    ],
    tracesSampleRate: 0.5,
  });
};
