import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import * as userApi from 'core/api/user';
import { auth } from 'routes/paths';

import useLanguagePref from './useLanguagePref';

const useAuth = () => {
  const { i18n } = useTranslation();
  const { setLanguagePref } = useLanguagePref();
  const {
    isLoading,
    data: response,
    error,
  } = useQuery('carriers/check-auth', userApi.checkAuth, {
    onSuccess: ({ data }) => {
      if (data.hasValidCredentials) {
        // if user is authenticated we should read the language value from the backend
        if (data?.language !== i18n.language) {
          setLanguagePref(data?.language);
        }
      }
    },
  });

  const READY_TO_OPERATE_STATUS = 'ready_to_operate';
  const READY_FOR_REVIEW_STATUS = 'ready_for_review';
  const COMPLIANCE_REJECTION_STATUS = 'compliance_rejection';
  const PROFILED_STATUS = 'profiled';

  const { pathname } = document.location;
  const isAuthenticated = response?.data?.hasValidCredentials || false;
  const currentUser = response?.data;
  const shouldRedirect =
    !!pathname &&
    !['/', auth.forgotPassword, auth.resetPassword].includes(pathname);

  return {
    isLoading,
    isAuthenticated,
    backUrl:
      !isAuthenticated &&
      shouldRedirect &&
      `/?redirect=${pathname}&expiredSession=true`,
    currentUser,
    isReadyForReview: currentUser?.companyStatus === READY_FOR_REVIEW_STATUS,
    isReadyToOperate: currentUser?.companyStatus === READY_TO_OPERATE_STATUS,
    isComplianceRejection:
      currentUser?.companyStatus === COMPLIANCE_REJECTION_STATUS,
    isProfiled: currentUser?.companyStatus === PROFILED_STATUS,
    error,
  };
};

export default useAuth;
