import { createContext, useState } from 'react';

import { Toast, ToastContainer } from '@nuvocargo/nuvo-styleguide';

const ToastContext = createContext({});

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const renderToasts = () => {
    return toasts.map((toast, index) => (
      <Toast
        key={index}
        type={toast.type || 'success'}
        autohide={{ enabled: true, duration: 3000 }}
      >
        <p>{toast.caption}</p>
      </Toast>
    ));
  };

  return (
    <ToastContext.Provider value={{ toasts, setToasts }}>
      {children}
      <ToastContainer>{renderToasts()}</ToastContainer>
    </ToastContext.Provider>
  );
};

export { ToastProvider, ToastContext };
