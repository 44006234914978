import { useState } from 'react';

import { useMutation, useQueryClient } from 'react-query';

import * as userApi from 'core/api/user';

const useSignOut = () => {
  const queryClient = useQueryClient();
  const [hasSignedOut, setHasSignedOut] = useState(false);

  const { mutate } = useMutation(userApi.signOut, {
    onSuccess: () => {
      queryClient.invalidateQueries('carriers/check-auth');
      setHasSignedOut(true);
    },
  });

  return {
    signOut: mutate,
    hasSignedOut,
  };
};

export default useSignOut;
