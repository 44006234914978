import { Suspense } from 'react';

/*
  Style css files need to be on top to prevent tailwind from taking precedence over styled components,
  feel free to reorder once we move out of tailwind.
*/
import './index.css';
import './styles/font.css';

import { PageHeaderProvider } from '@nuvocargo/nuvo-styleguide';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import 'core/integrations';
import Context from 'context';
import EventTracker from 'core/integrations/eventTracker';
import * as Sentry from 'core/integrations/sentry';
import {
  isInternalServerError,
  isNetworkError,
  isNotFoundError,
} from 'core/utils/httpErrors';
import { GlobalStyle, theme } from 'styles';

import App from './app';
import { Spinner } from './components/generic/kit';
import { ToastProvider } from './context/toast';
import reportWebVitals from './reportWebVitals';

const shouldTriggerErrorBoundary = error =>
  isNotFoundError(error?.response?.status) ||
  isInternalServerError(error?.response?.status) ||
  isNetworkError(error.response);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      useErrorBoundary: shouldTriggerErrorBoundary,
    },
    mutations: {
      useErrorBoundary: shouldTriggerErrorBoundary,
    },
  },
});

EventTracker.init();
Sentry.init();

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_MSW_ENABLED === 'true'
) {
  const { worker } = require('./mocks/browser');
  worker.start();
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <QueryClientProvider client={queryClient}>
      <PageHeaderProvider>
        <Sentry.ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <BrowserRouter>
              <Context>
                <ToastProvider>
                  <App />
                </ToastProvider>
              </Context>
              <ReactQueryDevtools initialIsOpen={false} />
            </BrowserRouter>
          </Suspense>
        </Sentry.ErrorBoundary>
      </PageHeaderProvider>
    </QueryClientProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
