export const app = {
  main: '/',
  billing: '/billing',
  billingDetails: '/billing/:uuid',
  loads: '/loads',
  loadDetails: '/loads/:uuid',
  settings: '/settings',
};

export const auth = {
  login: '/auth/login',
  forgotPassword: '/password/forgot',
  resetPassword: '/password/reset',
  magicLink: '/auth/link',
  linkSent: '/auth/link-sent',
};

export const account = {
  create: '/register/:uuid',
  complete: '/complete/:uuid',
};

export const onboarding = {
  verifyBusiness: '/onboarding/verify-business',
  payout: '/onboarding/payout-information',
  fleet: '/onboarding/fleet',
  services: '/onboarding/services',
  locations: '/onboarding/locations',
  success: '/onboarding/success',
  legalAndCompliance: '/onboarding/legal-and-compliance',
};

export const profiling = {
  main: '/profiling/:token',
  success: '/profiling/success',
};

export const billing = {
  main: '/billing',
};
