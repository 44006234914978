import qs from 'qs';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import * as userApi from 'core/api/user';
import { createFsEvent } from 'core/integrations/fullStory';

const useSignIn = () => {
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const { redirect } = qs.parse(search, { ignoreQueryPrefix: true });
  const { mutate, error, isLoading } = useMutation(userApi.signIn, {
    onSuccess: () => {
      queryClient.invalidateQueries('carriers/check-auth');

      createFsEvent('[Carrier Platform] Login - Sign in');

      if (redirect) {
        //Workaround to solve redirection issue after logout
        window.location.replace(redirect);
      }
    },
  });
  const errorData = error?.response?.data;

  return {
    signIn: mutate,
    isLoadingSignIn: isLoading,
    errorData: errorData,
  };
};

export default useSignIn;
