import { useTranslation } from 'react-i18next';

import ErrorPageTemplate from './Template';

export default function ErrorPage() {
  const { t } = useTranslation();

  return (
    <ErrorPageTemplate
      icon="flattire"
      title={t('error-page-title')}
      subtitle={t('error-page-subtitle')}
      actionText={t('error-page-button')}
      actionHref="/"
    />
  );
}
