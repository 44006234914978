import { useTranslation } from 'react-i18next';

import ErrorPageTemplate from './Template';

export default function Error404Page() {
  const { t } = useTranslation();

  return (
    <ErrorPageTemplate
      icon="truck_error"
      title={t('error-404-page-title')}
      subtitle={t('error-404-page-subtitle')}
      actionText={t('error-404-page-button')}
      actionHref="/"
    />
  );
}
