import styled from 'styled-components';

import { countryShortNameToFlag } from 'core/utils/lib';

const Flag = styled.img.attrs(({ icon }) => ({
  src: countryShortNameToFlag(icon),
}))`
  width: 21px;
  height: 16px;
`;

export default Flag;
