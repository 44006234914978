import request from 'core/utils/request';

export const signIn = data =>
  request('carriers/login', 'post', { body: { ...data } });

export const checkAuth = token =>
  request('carriers/check-auth', 'get', {
    headers: token && {
      'X-NUVOCARGO-MAGIC-LINK-TOKEN': token,
    },
  });

export const sendMagicLink = data =>
  request('/magic-link', 'post', { body: data });

export const signOut = () => request('carriers/logout', 'get');

export const forgotPassword = ({ email }) =>
  request('/password/forgot', 'post', { body: { email } });

export const resetPassword = ({ token, password }) =>
  request('/password/reset', 'post', { body: { token, password } });

export const verifyToken = ({ token }) =>
  request('/password/token', 'post', { body: { token } });

export const validateUser = async data =>
  await request('/onboarding/validate', 'post', { body: data });

export const updateLanguage = lng =>
  request('/users/language', 'put', { body: { language: lng } });

export const createRatchetAccount = async data => {
  return await request('/carriers', 'post', { body: { ...data } });
};

export const getOnboardingUuid = onboardingUuid =>
  request(`/carriers/onboarding_information/${onboardingUuid}`, 'get');

export const updateOnboardingLinkStatus = (onboardingUuid, status) =>
  request(`/carriers/onboarding_information/${onboardingUuid}`, 'PATCH', {
    body: status,
  });

export const updateUser = async body => {
  return await request('/carriers/users', 'patch', { body: { ...body } });
};
