import {
  CTAButton,
  Iconography,
  Heading,
  Text,
} from '@nuvocargo/nuvo-styleguide';
import styled from 'styled-components';

export default function ErrorPageTemplate({
  icon,
  title,
  subtitle,
  actionText,
  actionHref,
}) {
  return (
    <Main>
      <div style={{ textAlign: 'center' }}>
        <StyledIconography name={icon} />
      </div>
      <div style={{ marginTop: '10px', textAlign: 'center' }}>
        <Heading variant="h2B" color="forest">
          {title}
        </Heading>
      </div>
      <div style={{ marginTop: '22px', textAlign: 'center' }}>
        <Text style={{ fontSize: '18px' }}>{subtitle}</Text>
      </div>
      <div
        style={{
          marginTop: '60px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CTAButton isLink={true} href={actionHref}>
          {actionText}
        </CTAButton>
      </div>
    </Main>
  );
}

const StyledIconography = styled(Iconography)`
  && {
    width: 200px;
    height: 200px;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: inherit;
  min-height: 450px;
  width: 100%;
`;
